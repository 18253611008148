<template>
  <v-main>
    <v-container class="main-container h-100" fluid>
      <h2 class="ml-4">{{ $_strings.orderHome.DESC_ORDER }}</h2>
      <v-row class="mb-4" justify="space-around">
        <v-col cols="auto">
          <summary-item
            status="new"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.created"
            href="/main/status-pesanan/list/process"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="pickup"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.pickup"
            href="/main/status-pesanan/list/process"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="delivery"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.delivery"
            href="/main/status-pesanan/list/process"
          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="confirmation"
            :count="shipmentSummary.confirm"
            :loading="isLoadingShipmentSummary"
            :href="`/main/status-pesanan/list/needconfirmation?fromDate=${dayjs().subtract('60', 'days').format('YYYY-MM-DD')}`"

          />
        </v-col>
        <v-col cols="auto">
          <summary-item
            status="complete"
            :loading="isLoadingShipmentSummary"
            :count="shipmentSummary.completed && (shipmentSummary.completed > 999 ? '999+' : shipmentSummary.completed)"
            href="/main/status-pesanan/list/completed"
          />
        </v-col>
      </v-row>
      <v-row v-if="userAccess.canCreate" class="mt-12" justify="center">
        <v-col cols="12" sm="8" md="5">
          <v-sheet
            class="mb-10 summary-item mt-5 text-left d-flex text-decoration-none"
            rounded
            elevation="3"
            tag="a"
            min-width="250"
          >
            <v-container class="mb-2 pa-4 d-inline-flex">
              <v-row
                justify="center"
                align="center"
              >
                <v-col class="text-center" cols="10">
                  {{ $_strings.orderHome.GREET }} {{ user.name }}
                </v-col>
                <v-col class="text-center" cols="10">
                  {{ $_strings.orderHome.QUESTION }}
                </v-col>
                <v-col class="text-center" cols="10">
                  <v-btn
                    @click="$router.push('/main/buat-pesanan/create')"
                    large
                    color="primary"
                    class="white--text font-weight-bold body-2 w-100"
                    type="submit"
                  >
                    {{ $_strings.orderHome.CREATE_NEW_ORDER }}
                  </v-btn>
                </v-col>
                <v-col class="text-center" cols="10">
                  <v-btn
                    @click="$refs.fileInput.click()"
                    :loading="uploadingCsv"
                    large
                    color="#4964F0"
                    class="white--text font-weight-bold body-2 w-100"
                  >
                    {{ $_strings.order.UPLOAD_SHIPMENTS }}
                  </v-btn>
                </v-col>
                <v-col class="text-center" cols="10">
                  <v-btn
                    :loading="downloadingCsv"
                    @click="downloadCsvTemplate"
                    large
                    color="primary"
                    outlined
                    class="white--text font-weight-bold body-2 w-100"
                  >
                    {{ $_strings.order.TEMPLATE_UPLOAD_SHIPMENTS }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
      <input
        style="display: none;"
        id="csv-upload"
        :disabled="uploadingCsv"
        ref="fileInput"
        type="file"
        accept=".csv"
        @change="uploadCsv"
      />
    </v-container>
  </v-main>
</template>

<script>
import { getDateTimeTz, parseCsvRow } from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import axios from 'axios';
import SummaryItem from './SummaryItem';

const { exportCSVFile } = require('../../helper/csvGenerator');

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'OrderPage',
  components: {
    SummaryItem,
  },
  data() {
    return {
      downloadingCsv: false,
      uploadingCsv: false,
      isLoadingShipmentSummary: false,
      shipmentSummary: {
        completed: '',
        confirm: '',
        created: '',
        delivery: '',
        pickup: '',
      },
      user: {
        name: '',
        role: '',
      },
    };
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  mounted() {
    const dataUser = this.$store.state.user.myUserInfo;
    this.user.name = dataUser.username;
    this.getShipmentSummary();
  },
  methods: {
    dayjs,
    getDateTimeTz,
    async downloadCsvTemplate() {
      try {
        this.downloadingCsv = true;
        const result = await this.$_services.order.templateShipmentCSV();
        const { column, filename } = result.data;
        exportCSVFile(null, [[...column]], filename);
      } finally {
        this.downloadingCsv = false;
      }
    },
    mapToForm(data) {
      const {
        TANGGAL_PICKUP,
        SERVICE_TYPE,
        TRANSPORT_TYPE,
        KATEGORI_BARANG,
        SUB_KATEGORI_BARANG,
        LOKASI_ASAL,
        LOKASI_TUJUAN,
        KATEGORI_BARANG_BACKHAULING,
        SUB_KATEGORI_BARANG_BACKHAULING,
        LOKASI_ASAL_BACKHAULING,
        LOKASI_TUJUAN_BACKHAULING,
        NO_TLP_PENERIMA_BACKHAULING,
      } = data;

      // Membentuk objek dengan format yang diinginkan

      return {
        pickupDate: dayjs(TANGGAL_PICKUP).format(),
        serviceType: SERVICE_TYPE,
        transportTypeId: TRANSPORT_TYPE,
        categoryId: KATEGORI_BARANG,
        subCategoryId: SUB_KATEGORI_BARANG,
        originLocationId: LOKASI_ASAL,
        destinationLocationId: LOKASI_TUJUAN,
        backhauling: KATEGORI_BARANG_BACKHAULING || SUB_KATEGORI_BARANG_BACKHAULING
                     || LOKASI_TUJUAN_BACKHAULING || NO_TLP_PENERIMA_BACKHAULING
          ? {
            categoryId: KATEGORI_BARANG_BACKHAULING,
            subCategoryId: SUB_KATEGORI_BARANG_BACKHAULING,
            destinationLocationId: LOKASI_TUJUAN_BACKHAULING,
            destinationReceiverPhone: NO_TLP_PENERIMA_BACKHAULING,
            shipmentsDetail: [],
          }
          : null, // Jika tidak ada data backhauling, maka set ke null
        items: {
          itemsVehicle: [{
            transportTypeId: TRANSPORT_TYPE,
            name: TRANSPORT_TYPE,
          }],
          itemsOrigin: [{
            value: LOKASI_ASAL,
            text: LOKASI_ASAL,
          }],
          itemsDestination: [{
            value: LOKASI_TUJUAN,
            text: LOKASI_TUJUAN,
          }],
          itemsCategory: [{
            id: KATEGORI_BARANG,
            name: KATEGORI_BARANG,
          }],
          itemsSubCategory: [{
            id: SUB_KATEGORI_BARANG,
            name: SUB_KATEGORI_BARANG,
          }],
          // ITEMS FOR BACKHAULING
          itemsOriginBackhauling: [{
            value: LOKASI_ASAL_BACKHAULING,
            text: LOKASI_ASAL_BACKHAULING,
          }],
          itemsDestinationBackhauling: [{
            value: LOKASI_TUJUAN_BACKHAULING,
            text: LOKASI_TUJUAN_BACKHAULING,
          }],
          itemsSubCategoryBackhauling: [{
            id: SUB_KATEGORI_BARANG_BACKHAULING,
            name: SUB_KATEGORI_BARANG_BACKHAULING,
          }],
        },
      };
    },
    async uploadCsv() {
      const file = this.$refs.fileInput.files[0];
      const typeSupport = ['csv'];
      const fileType = file.name.split('.').pop().toLowerCase();
      if (!typeSupport.includes(fileType)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return;
      }
      const formData = new FormData();
      formData.append('timezone', this.getDateTimeTz());
      formData.append('file', file);
      const reader = new FileReader();
      const orders = [];
      const _this = this;
      // eslint-disable-next-line func-names
      reader.onload = function (e) {
        const text = e.target.result;
        const csvData = text.split('\n'); // Memisahkan baris CSV
        const [headerRow, dataRow] = csvData;
        const allHeaders = headerRow.split(';');

        // Pisahkan header dan data
        const desiredHeaders = [
          'SERVICE_TYPE',
          'TRANSPORT_TYPE',
          'KATEGORI_BARANG',
          'SUB_KATEGORI_BARANG',
          'LOKASI_ASAL',
          'LOKASI_TUJUAN',
          'KATEGORI_BARANG_BACKHAULING',
          'SUB_KATEGORI_BARANG_BACKHAULING',
          'LOKASI_ASAL_BACKHAULING',
          'LOKASI_TUJUAN_BACKHAULING',
        ];
        const result = parseCsvRow(dataRow, desiredHeaders, allHeaders);
        orders.push(_this.mapToForm(result));
      };

      reader.readAsText(file);
      reader.onloadend = () => {
        this.navigateToPageUpload({ orders, formData });
      };
      const input = this.$refs.fileInput;
      input.type = 'text';
      input.type = 'file';
    },
    navigateToPageUpload({ orders, formData }) {
      this.$router.push({
        name: 'create',
        params: {
          orders,
          upload: true,
          formData,
        },
      });
    },
    getShipmentSummary() {
      this.isLoadingShipmentSummary = true;
      this.$_services.order.getShipmentSummary(source)
        .then((res) => {
          this.shipmentSummary = res?.data;
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          this.isLoadingShipmentSummary = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  background-image: url('~@/assets/images/bg-login.png');
  background-size: 920px 337px;
  background-position: bottom;
  background-color: $--color-bg-alabaster;
  padding: 0 !important;
  min-height: 95%;
  .container {
    background: transparent;
  }
}
</style>
